
.card {
  /* max-width: 40rem; */
  /* max-height: 40rem; */
  width: 21rem;
  height: 21rem;
  background: grey;
  border-radius: 5px;
  background-image: url('../../assets/images/doctor6.jpg');
  background-size: cover;
  background-position: top;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.5s;
  will-change: transform;
  border: 15px solid white;
}

@media screen and (max-width: 600px) {
  .card {
    width: 15rem;
  }
}

.card:hover {
  box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);
}
